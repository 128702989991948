import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr>\n  <td>\n    {{@file.id}}\n  </td>\n  <td>\n    {{@file.name}}\n  </td>\n\n  <td class='table-button'>\n    <LinkTo\n      @route='authenticated.security.file'\n      @model={{@file.id}}\n      class='button mp-all-scans is-primary'\n    >\n      View\n    </LinkTo>\n  </td>\n\n  <td>\n    <button\n      disabled={{this.triggerDownloadApp.isRunning}}\n      class='button mp-all-scans is-primary'\n      type='button'\n      {{on 'click' this.downloadApp}}\n    >\n      {{#if this.triggerDownloadApp.isRunning}}\n        <AkLoader @size={{13}} class='mr-1' />\n      {{/if}}\n\n      Download\n      {{@file.fileFormatDisplay}}\n    </button>\n  </td>\n</tr>", {"contents":"<tr>\n  <td>\n    {{@file.id}}\n  </td>\n  <td>\n    {{@file.name}}\n  </td>\n\n  <td class='table-button'>\n    <LinkTo\n      @route='authenticated.security.file'\n      @model={{@file.id}}\n      class='button mp-all-scans is-primary'\n    >\n      View\n    </LinkTo>\n  </td>\n\n  <td>\n    <button\n      disabled={{this.triggerDownloadApp.isRunning}}\n      class='button mp-all-scans is-primary'\n      type='button'\n      {{on 'click' this.downloadApp}}\n    >\n      {{#if this.triggerDownloadApp.isRunning}}\n        <AkLoader @size={{13}} class='mr-1' />\n      {{/if}}\n\n      Download\n      {{@file.fileFormatDisplay}}\n    </button>\n  </td>\n</tr>","moduleName":"irene/components/security/file-search-overview/index.hbs","parseOptions":{"srcName":"irene/components/security/file-search-overview/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import SecurityFileModel from 'irene/models/security/file';
import parseError from 'irene/utils/parse-error';
import ENV from 'irene/config/environment';

export interface SecurityFileSearchOverviewSignature {
  Element: HTMLElement;
  Args: {
    file: SecurityFileModel;
  };
}

export default class SecurityFileSearchOverviewComponent extends Component<SecurityFileSearchOverviewSignature> {
  @service('notifications') declare notify: NotificationService;
  @service('browser/window') declare window: Window;
  @service('intl') declare intl: IntlService;
  @service declare ajax: any;

  get file() {
    return this.args.file;
  }

  @action downloadApp() {
    this.triggerDownloadApp.perform();
  }

  triggerDownloadApp = task(async () => {
    try {
      const fileId = this.file.id;
      const url = [ENV.endpoints['apps'], fileId].join('/');

      const data = (await this.ajax.request(url, {
        namespace: 'api/hudson-api',
      })) as { url: string };

      this.window.open(data.url, '_blank');
    } catch (e) {
      this.notify.error(parseError(e, this.intl.t('pleaseTryAgain')));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileSearchOverview': typeof SecurityFileSearchOverviewComponent;
  }
}
