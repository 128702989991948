import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  local-class='app-logo-container'\n  @alignItems='center'\n  @justifyContent='center'\n>\n  <img data-test-sbomApp-logo src={{this.iconUrl}} alt='' role='none' />\n</AkStack>", {"contents":"<AkStack\n  local-class='app-logo-container'\n  @alignItems='center'\n  @justifyContent='center'\n>\n  <img data-test-sbomApp-logo src={{this.iconUrl}} alt='' role='none' />\n</AkStack>","moduleName":"irene/components/sbom/app-logo/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-logo/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';

export interface SbomAppLogoSignature {
  Args: {
    sbomProject?: SbomProjectModel;
  };
}

export default class SbomAppLogoComponent extends Component<SbomAppLogoSignature> {
  get iconUrl() {
    return this.args.sbomProject?.project.get('lastFile')?.get('iconUrl');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppLogo': typeof SbomAppLogoComponent;
  }
}
