import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='1'>\n  <Sbom::AppLogo @sbomProject={{@sbomProject}} />\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography data-test-sbomApp-packageName @tag='span'>\n      {{this.packageName}}\n    </AkTypography>\n\n    <AkTypography data-test-sbomApp-name @tag='span' @color='textSecondary'>\n      {{this.name}}\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='1'>\n  <Sbom::AppLogo @sbomProject={{@sbomProject}} />\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography data-test-sbomApp-packageName @tag='span'>\n      {{this.packageName}}\n    </AkTypography>\n\n    <AkTypography data-test-sbomApp-name @tag='span' @color='textSecondary'>\n      {{this.name}}\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/sbom/app-list/app-name/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-list/app-name/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';

export interface SbomProjectListAppNameSignature {
  Args: {
    sbomProject: SbomProjectModel;
  };
}

export default class SbomProjectListAppNameComponent extends Component<SbomProjectListAppNameSignature> {
  get packageName() {
    return this.args.sbomProject.project.get('packageName');
  }

  get name() {
    return this.args.sbomProject.project.get('lastFile')?.get('name');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppList::AppName': typeof SbomProjectListAppNameComponent;
    'sbom/app-list/app-name': typeof SbomProjectListAppNameComponent;
  }
}
