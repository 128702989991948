export default {
  "header": "_header_1scctn",
  "title": "_title_1scctn",
  "table": "_table_1scctn",
  "table-header": "_table-header_1scctn",
  "table-row": "_table-row_1scctn",
  "has-report-column": "_has-report-column_1scctn",
  "has-results-column": "_has-results-column_1scctn",
  "app": "_app_1scctn",
  "app-icon": "_app-icon_1scctn",
  "app-name": "_app-name_1scctn",
  "fileid-key": "_fileid-key_1scctn",
  "fileid-value": "_fileid-value_1scctn",
  "version-info": "_version-info_1scctn",
  "version": "_version_1scctn",
  "version-key": "_version-key_1scctn",
  "version-value": "_version-value_1scctn",
  "uploaded": "_uploaded_1scctn",
  "summary": "_summary_1scctn",
  "pagination": "_pagination_1scctn",
  "loading-error": "_loading-error_1scctn",
  "loading": "_loading_1scctn",
  "empty-data": "_empty-data_1scctn"
};
