export default {
  "app-details-container": "_app-details-container_1hz763",
  "app-details-wrapper": "_app-details-wrapper_1hz763",
  "overview-container": "_overview-container_1hz763",
  "overview-icons": "_overview-icons_1hz763",
  "platform-icon": "_platform-icon_1hz763",
  "project-icon": "_project-icon_1hz763",
  "platform-android": "_platform-android_1hz763",
  "platform-apple": "_platform-apple_1hz763",
  "monitoring-toggle-container": "_monitoring-toggle-container_1hz763",
  "monitoring-toggle": "_monitoring-toggle_1hz763",
  "monitoring-details-container": "_monitoring-details-container_1hz763",
  "monitoring-details-group": "_monitoring-details-group_1hz763",
  "details-header": "_details-header_1hz763",
  "details-value": "_details-value_1hz763",
  "app-details-tabs": "_app-details-tabs_1hz763"
};
