import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not, perform}}\n<AkStack\n  @direction='row'\n  @justifyContent='space-between'\n  @alignItems='center'\n  @width='full'\n  local-class='settings-block'\n>\n  <AkStack @direction='column' @width='10/12'>\n    <AkTypography\n      @variant='h5'\n      @gutterBottom={{true}}\n      data-test-app-monitoring-settings-heading\n    >\n      {{t 'appMonitoringHeading'}}\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      local-class='settings-description'\n      data-test-app-monitoring-settings-description\n    >\n      {{t 'appMonitoringDescription'}}\n    </AkTypography>\n  </AkStack>\n\n  <AkToggle\n    @size='small'\n    @checked={{@settings.enabled}}\n    @disabled={{not this.canEditSettings}}\n    @onChange={{perform this.toggleAppMonitoringEnabled}}\n  />\n</AkStack>", {"contents":"{{! @glint-nocheck: not, perform}}\n<AkStack\n  @direction='row'\n  @justifyContent='space-between'\n  @alignItems='center'\n  @width='full'\n  local-class='settings-block'\n>\n  <AkStack @direction='column' @width='10/12'>\n    <AkTypography\n      @variant='h5'\n      @gutterBottom={{true}}\n      data-test-app-monitoring-settings-heading\n    >\n      {{t 'appMonitoringHeading'}}\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      local-class='settings-description'\n      data-test-app-monitoring-settings-description\n    >\n      {{t 'appMonitoringDescription'}}\n    </AkTypography>\n  </AkStack>\n\n  <AkToggle\n    @size='small'\n    @checked={{@settings.enabled}}\n    @disabled={{not this.canEditSettings}}\n    @onChange={{perform this.toggleAppMonitoringEnabled}}\n  />\n</AkStack>","moduleName":"irene/components/app-monitoring/settings/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/settings/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import AmConfigurationModel from 'irene/models/amconfiguration';
import MeService from 'irene/services/me';
import OrganizationService from 'irene/services/organization';
import AppMonitoringService from 'irene/services/appmonitoring';

interface AppMonitoringSettingsSignature {
  Args: {
    settings: AmConfigurationModel | undefined;
  };
}

export default class AppMonitoringSettingsComponent extends Component<AppMonitoringSettingsSignature> {
  @service declare intl: IntlService;
  @service declare me: MeService;
  @service declare organization: OrganizationService;
  @service declare appmonitoring: AppMonitoringService;

  get canEditSettings() {
    return (
      this.me.org?.get('is_admin') &&
      this.organization.selectedOrgProjectsCount > 0
    );
  }

  toggleAppMonitoringEnabled = task(async (_, checked: boolean) => {
    const settings = this.args.settings;

    if (settings) {
      settings.set('enabled', checked);
      await settings.save();

      this.appmonitoring.reload();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppMonitoring::Settings': typeof AppMonitoringSettingsComponent;
  }
}
