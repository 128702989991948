import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @justifyContent={{or @justifyContent 'flex-end'}}\n  @alignItems='center'\n  @flexWrap='wrap'\n  @spacing='1'\n>\n  {{#each this.tags as |tag|}}\n    <AkTooltip\n      @arrow={{true}}\n      @placement={{or @tooltipPlacement 'top'}}\n      @color='dark'\n      @title='{{t tag.text}} {{t\n        (if tag.status \"scanCompleted\" \"scanNotCompleted\")\n      }}'\n    >\n      <AkChip\n        {{style height=(if this.isSizeMedium '26px')}}\n        @variant='semi-filled'\n        @size='small'\n        @label={{t tag.text}}\n        @color={{if tag.status 'success' 'error'}}\n        data-test-vulnerability-analysis-scanTypeTag\n      >\n        <:icon>\n          <AkIcon @iconName={{if tag.status 'check-circle' 'cancel'}} />\n        </:icon>\n      </AkChip>\n    </AkTooltip>\n  {{/each}}\n</AkStack>", {"contents":"<AkStack\n  @justifyContent={{or @justifyContent 'flex-end'}}\n  @alignItems='center'\n  @flexWrap='wrap'\n  @spacing='1'\n>\n  {{#each this.tags as |tag|}}\n    <AkTooltip\n      @arrow={{true}}\n      @placement={{or @tooltipPlacement 'top'}}\n      @color='dark'\n      @title='{{t tag.text}} {{t\n        (if tag.status \"scanCompleted\" \"scanNotCompleted\")\n      }}'\n    >\n      <AkChip\n        {{style height=(if this.isSizeMedium '26px')}}\n        @variant='semi-filled'\n        @size='small'\n        @label={{t tag.text}}\n        @color={{if tag.status 'success' 'error'}}\n        data-test-vulnerability-analysis-scanTypeTag\n      >\n        <:icon>\n          <AkIcon @iconName={{if tag.status 'check-circle' 'cancel'}} />\n        </:icon>\n      </AkChip>\n    </AkTooltip>\n  {{/each}}\n</AkStack>","moduleName":"irene/components/file-details/type-of-scan/index.hbs","parseOptions":{"srcName":"irene/components/file-details/type-of-scan/index.hbs"}});
import Component from '@glimmer/component';
import AnalysisModel from 'irene/models/analysis';
import ENUMS from 'irene/enums';

export interface FileDetailsVulnerabilityAnalysesTypeOfScanSignature {
  Args: {
    analysis: AnalysisModel;
    chipSize?: 'small' | 'medium';
    justifyContent?: 'flex-start' | 'flex-end';
    tooltipPlacement?: 'top' | 'bottom';
  };
}

export default class FileDetailsVulnerabilityAnalysesTypeOfScanComponent extends Component<FileDetailsVulnerabilityAnalysesTypeOfScanSignature> {
  get isSizeMedium() {
    return this.args.chipSize === 'medium';
  }

  get tags() {
    const types = this.args.analysis.vulnerabilityTypes;

    if (types === undefined) {
      return [];
    }

    const tags = [];

    for (const type of Array.from(types)) {
      if (type === ENUMS.VULNERABILITY_TYPE.STATIC) {
        tags.push({
          status: this.args.analysis.file.get?.('isStaticDone'),
          text: 'static',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.DYNAMIC) {
        tags.push({
          status: this.args.analysis.file.get?.('isDynamicDone'),
          text: 'dynamic',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.MANUAL) {
        tags.push({
          status: this.args.analysis.file.get?.('isManualDone'),
          text: 'manual',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.API) {
        tags.push({
          status: this.args.analysis.file.get?.('isApiDone'),
          text: 'api',
        });
      }
    }

    return tags;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::TypeOfScan': typeof FileDetailsVulnerabilityAnalysesTypeOfScanComponent;
    'file-details/type-of-scan': typeof FileDetailsVulnerabilityAnalysesTypeOfScanComponent;
  }
}
