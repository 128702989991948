import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  data-test-ak-form-label-root\n  ...attributes\n  local-class='ak-form-control-label-root ak-form-control-label-placement-{{this.placement}}'\n>\n  {{yield (hash control=(component 'blank-template') disabled=@disabled)}}\n\n  {{#if (has-block 'label')}}\n    {{yield to='label'}}\n  {{else}}\n    {{#if @label}}\n      <AkTypography\n        @tag='span'\n        @variant={{@labelTypographyVariant}}\n        @noWrap={{@labelTypographyNoWrap}}\n        @color={{@labelTypographyColor}}\n        @fontWeight={{@labelTypographyFontWeight}}\n        data-test-ak-form-label\n        local-class='\n        ak-form-control-label \n        {{if @disabled \"ak-form-control-label-disabled\"}}'\n      >\n        {{@label}}\n      </AkTypography>\n    {{/if}}\n  {{/if}}\n</label>", {"contents":"<label\n  data-test-ak-form-label-root\n  ...attributes\n  local-class='ak-form-control-label-root ak-form-control-label-placement-{{this.placement}}'\n>\n  {{yield (hash control=(component 'blank-template') disabled=@disabled)}}\n\n  {{#if (has-block 'label')}}\n    {{yield to='label'}}\n  {{else}}\n    {{#if @label}}\n      <AkTypography\n        @tag='span'\n        @variant={{@labelTypographyVariant}}\n        @noWrap={{@labelTypographyNoWrap}}\n        @color={{@labelTypographyColor}}\n        @fontWeight={{@labelTypographyFontWeight}}\n        data-test-ak-form-label\n        local-class='\n        ak-form-control-label \n        {{if @disabled \"ak-form-control-label-disabled\"}}'\n      >\n        {{@label}}\n      </AkTypography>\n    {{/if}}\n  {{/if}}\n</label>","moduleName":"irene/components/ak-form-control-label/index.hbs","parseOptions":{"srcName":"irene/components/ak-form-control-label/index.hbs"}});
import Component from '@glimmer/component';
import {
  TypographyColors,
  TypographyFontWeight,
  TypographyVariant,
} from '../ak-typography';
import { ComponentLike } from '@glint/template';

type LabelTypographyVariant = TypographyVariant;
type LabelTypographyFontWeight = TypographyFontWeight;
type LabelTypographyColor = TypographyColors;

export interface AkFormControlLabelSignature {
  Element: HTMLLabelElement;
  Args: {
    placement?: 'right' | 'left';
    disabled?: boolean;
    label?: string;
    labelTypographyFontWeight?: LabelTypographyFontWeight;
    labelTypographyVariant?: LabelTypographyVariant;
    labelTypographyNoWrap?: boolean;
    labelTypographyColor?: LabelTypographyColor;
  };
  Blocks: {
    default: [
      {
        control: ComponentLike<{ Blocks: { default: [] } }>;
        disabled?: boolean;
      }
    ];
    label: [];
  };
}

export default class AkFormControlLabelComponent extends Component<AkFormControlLabelSignature> {
  get placement() {
    return this.args.placement || 'right';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkFormControlLabel: typeof AkFormControlLabelComponent;
  }
}
