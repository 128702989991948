export default {
  "container": "_container_ts5uz1",
  "inner-container": "_inner-container_ts5uz1",
  "login-container": "_login-container_ts5uz1",
  "login-title": "_login-title_ts5uz1",
  "login-footer": "_login-footer_ts5uz1",
  "register-link": "_register-link_ts5uz1",
  "action-container": "_action-container_ts5uz1",
  "input-group": "_input-group_ts5uz1",
  "input": "_input_ts5uz1",
  "input-connect-label": "_input-connect-label_ts5uz1",
  "input-label": "_input-label_ts5uz1",
  "forgot-password": "_forgot-password_ts5uz1",
  "login-button": "_login-button_ts5uz1",
  "login-button-text": "_login-button-text_ts5uz1",
  "ak-button-loader": "_ak-button-loader_ts5uz1"
};
