import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='center'\n  @justifyContent='flex-end'\n  @spacing='1'\n  class='pr-2'\n>\n  <AkToggle\n    @size='small'\n    @checked={{@scenario.isActive}}\n    @onChange={{this.toggleScenarioStatus}}\n    @disabled={{this.updateScenarioStatus.isRunning}}\n    {{on 'click' this.handleToggleClick}}\n    {{style margin='0px'}}\n    data-test-projectSettings-dastScenario-toggle\n  />\n\n  {{#unless @hideToggleLoader}}\n    {{#if this.updateScenarioStatus.isRunning}}\n      <AkLoader\n        @size={{14}}\n        data-test-projectSettings-dastScenario-statusToggleLoading\n      />\n    {{/if}}\n  {{/unless}}\n</AkStack>", {"contents":"<AkStack\n  @alignItems='center'\n  @justifyContent='flex-end'\n  @spacing='1'\n  class='pr-2'\n>\n  <AkToggle\n    @size='small'\n    @checked={{@scenario.isActive}}\n    @onChange={{this.toggleScenarioStatus}}\n    @disabled={{this.updateScenarioStatus.isRunning}}\n    {{on 'click' this.handleToggleClick}}\n    {{style margin='0px'}}\n    data-test-projectSettings-dastScenario-toggle\n  />\n\n  {{#unless @hideToggleLoader}}\n    {{#if this.updateScenarioStatus.isRunning}}\n      <AkLoader\n        @size={{14}}\n        data-test-projectSettings-dastScenario-statusToggleLoading\n      />\n    {{/if}}\n  {{/unless}}\n</AkStack>","moduleName":"irene/components/project-settings/dast-scenario-toggle/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/dast-scenario-toggle/index.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';

import ScanParameterGroupModel from 'irene/models/scan-parameter-group';
import ProjectModel from 'irene/models/project';
import parseError from 'irene/utils/parse-error';

export interface ProjectSettingsDastScenarioStatusToggleSignature {
  Args: {
    project?: ProjectModel | null;
    scenario: ScanParameterGroupModel;
    hideToggleLoader?: boolean;
  };
}

export default class ProjectSettingsDastScenarioStatusToggleComponent extends Component<ProjectSettingsDastScenarioStatusToggleSignature> {
  @service declare intl: IntlService;
  @service('notifications') declare notify: NotificationService;

  get scenario() {
    return this.args.scenario;
  }

  @action handleToggleClick(event: Event) {
    event.stopPropagation();
  }

  @action toggleScenarioStatus(_: Event, checked?: boolean) {
    this.updateScenarioStatus.perform(!!checked);
  }

  updateScenarioStatus = task(async (checked: boolean) => {
    try {
      this.scenario.set('isActive', checked);

      const adapterOptions = { projectId: this.args.project?.id };
      await this.scenario.save({ adapterOptions });

      this.notify.success(this.intl.t('dastAutomation.scenarioStatusUpdated'));
    } catch (error) {
      this.scenario.set('isActive', !checked);
      this.notify.error(parseError(error));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::DastScenarioToggle': typeof ProjectSettingsDastScenarioStatusToggleComponent;
  }
}
