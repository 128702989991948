import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='tabs is-medium is-boxed'>\n  <ul>\n    {{#each this.tabs as |tab|}}\n      <li class={{if tab.isActive 'is-active'}}>\n        <LinkTo @route={{tab.route}}>\n          {{tab.label}}\n        </LinkTo>\n      </li>\n    {{/each}}\n  </ul>\n</div>", {"contents":"<div class='tabs is-medium is-boxed'>\n  <ul>\n    {{#each this.tabs as |tab|}}\n      <li class={{if tab.isActive 'is-active'}}>\n        <LinkTo @route={{tab.route}}>\n          {{tab.label}}\n        </LinkTo>\n      </li>\n    {{/each}}\n  </ul>\n</div>","moduleName":"irene/components/account-settings/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

export default class AccountSettingsComponent extends Component {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get tabs() {
    return [
      {
        label: this.intl.t('general'),
        route: 'authenticated.settings.general',
        isActive:
          this.router.currentRouteName === 'authenticated.settings.general',
      },
      {
        label: this.intl.t('security'),
        route: 'authenticated.settings.security',
        isActive:
          this.router.currentRouteName === 'authenticated.settings.security',
      },
      {
        label: this.intl.t('developerSettings'),
        route: 'authenticated.settings.developersettings',
        isActive:
          this.router.currentRouteName ===
          'authenticated.settings.developersettings',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountSettings: typeof AccountSettingsComponent;
  }
}
