import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='table-container'>\n  <table class='security-list-table'>\n    <thead class='w-full'>\n      <tr>\n        <th>\n          Analysis ID\n        </th>\n        <th>\n          Vulnerability\n        </th>\n        <th>\n          Risk\n        </th>\n        <th>\n          Scan Type\n        </th>\n        <th>\n          Status\n        </th>\n      </tr>\n    </thead>\n\n    <tbody class='w-full'>\n      {{#each this.sortedAnalyses as |analysis|}}\n        <Security::AnalysisOverview\n          @analysis={{analysis}}\n          @fileDetails={{@fileDetails}}\n        />\n      {{/each}}\n    </tbody>\n  </table>\n</div>", {"contents":"<div class='table-container'>\n  <table class='security-list-table'>\n    <thead class='w-full'>\n      <tr>\n        <th>\n          Analysis ID\n        </th>\n        <th>\n          Vulnerability\n        </th>\n        <th>\n          Risk\n        </th>\n        <th>\n          Scan Type\n        </th>\n        <th>\n          Status\n        </th>\n      </tr>\n    </thead>\n\n    <tbody class='w-full'>\n      {{#each this.sortedAnalyses as |analysis|}}\n        <Security::AnalysisOverview\n          @analysis={{analysis}}\n          @fileDetails={{@fileDetails}}\n        />\n      {{/each}}\n    </tbody>\n  </table>\n</div>","moduleName":"irene/components/security/analysis-list/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-list/index.hbs"}});
import Component from '@glimmer/component';
import FileModel from 'irene/models/file';

export interface SecurityAnalysisListComponentSignature {
  Args: {
    fileDetails: FileModel;
  };
}

export default class SecurityAnalysisListComponent extends Component<SecurityAnalysisListComponentSignature> {
  get sortedAnalyses() {
    return this.args.fileDetails.get('sortedAnalyses');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::AnalysisList': typeof SecurityAnalysisListComponent;
  }
}
