import { isEmpty } from '@ember/utils';
import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';
import ENUMS from 'irene/enums';

export default Factory.extend({
  risk() {
    return faker.helpers.arrayElement([
      ENUMS.RISK.UNKNOWN,
      ENUMS.RISK.NONE,
      ENUMS.RISK.LOW,
      ENUMS.RISK.MEDIUM,
      ENUMS.RISK.HIGH,
      ENUMS.RISK.CRITICAL,
    ]);
  },

  riskOverridden() {
    const risk = this.risk as number;

    return !isEmpty(risk) || false;
  },

  riskIconClass() {
    const risk = this.risk as number;

    switch (risk) {
      case ENUMS.RISK.UNKNOWN:
        return 'fa-spinner fa-spin';
      case ENUMS.RISK.NONE:
        return 'fa-check';
      case ENUMS.RISK.CRITICAL:
      case ENUMS.RISK.HIGH:
      case ENUMS.RISK.LOW:
      case ENUMS.RISK.MEDIUM:
        return 'fa-warning';
    }
  },

  riskLabelClass() {
    const cls = 'tag';
    const risk = this.risk as number;

    switch (risk) {
      case ENUMS.RISK.UNKNOWN:
        return `${cls} is-progress`;
      case ENUMS.RISK.NONE:
        return `${cls} is-success`;
      case ENUMS.RISK.LOW:
        return `${cls} is-info`;
      case ENUMS.RISK.MEDIUM:
        return `${cls} is-warning`;
      case ENUMS.RISK.HIGH:
        return `${cls} is-danger`;
      case ENUMS.RISK.CRITICAL:
        return `${cls} is-critical`;
    }
  },
});
