export default {
  "container": "_container_1seek4",
  "flex-center": "_flex-center_1seek4",
  "form": "_form_1seek4",
  "text-center": "_text-center_1seek4",
  "reset-success-text": "_reset-success-text_1seek4",
  "reset-success-subtext": "_reset-success-subtext_1seek4",
  "send-reset-mail-btn": "_send-reset-mail-btn_1seek4",
  "input-wrapper": "_input-wrapper_1seek4",
  "has-error": "_has-error_1seek4",
  "input-field": "_input-field_1seek4",
  "error-msg": "_error-msg_1seek4",
  "login-link": "_login-link_1seek4"
};
