import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName='{{this.teamProject.platformIconClass}}'\n    local-class='team-project-icon team-project-icon-{{this.teamProject.platformIconClass}}'\n  />\n\n  <AkLink\n    @route='authenticated.project.files'\n    @model={{@project.id}}\n    @noWrap={{true}}\n    @title={{this.teamProject.packageName}}\n  >\n    {{this.teamProject.packageName}}\n  </AkLink>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName='{{this.teamProject.platformIconClass}}'\n    local-class='team-project-icon team-project-icon-{{this.teamProject.platformIconClass}}'\n  />\n\n  <AkLink\n    @route='authenticated.project.files'\n    @model={{@project.id}}\n    @noWrap={{true}}\n    @title={{this.teamProject.packageName}}\n  >\n    {{this.teamProject.packageName}}\n  </AkLink>\n</AkStack>","moduleName":"irene/components/organization-team/project-list/project-info/index.hbs","parseOptions":{"srcName":"irene/components/organization-team/project-list/project-info/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import OrganizationProjectModel from 'irene/models/organization-project';

export interface OrganizationTeamProjectListProjectInfoComponentSignature {
  Args: {
    project: OrganizationProjectModel;
  };
}

export default class OrganizationTeamProjectListProjectInfo extends Component<OrganizationTeamProjectListProjectInfoComponentSignature> {
  @service declare store: Store;

  get teamProject() {
    return this.store.findRecord('project', this.args.project.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationTeam::ProjectList::ProjectInfo': typeof OrganizationTeamProjectListProjectInfo;
  }
}
