import { faker } from '@faker-js/faker';
import ENUMS from 'irene/enums';
import Base from './base';

export default Base.extend({
  uuid: () => faker.string.uuid(),
  name: () => faker.company.name(),
  description: () => faker.lorem.sentence(),
  question: () => faker.lorem.sentence(),
  'success-message': () => faker.lorem.sentence(),
  'more-info': () => faker.lorem.sentence(),
  heading: () => faker.lorem.sentence(),
  intro: () => faker.lorem.sentence(),
  complaint: () => faker.lorem.sentence(),
  'non-complaint': () => faker.lorem.sentence(),
  'related-to': () => faker.lorem.sentence(),
  'business-implication': () => faker.lorem.sentence(),

  types() {
    const t = ENUMS.VULNERABILITY_TYPE;

    return faker.helpers.arrayElements([t.STATIC, t.DYNAMIC, t.MANUAL, t.API]);
  },
});
