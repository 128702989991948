export default {
  "columns": "_columns_8v8j8g",
  "column": "_column_8v8j8g",
  "is-full": "_is-full_8v8j8g",
  "is-half": "_is-half_8v8j8g",
  "is-one-third": "_is-one-third_8v8j8g",
  "is-one-quarter": "_is-one-quarter_8v8j8g",
  "is-one-sixth": "_is-one-sixth_8v8j8g",
  "pagination": "_pagination_8v8j8g",
  "action": "_action_8v8j8g",
  "pagination-list": "_pagination-list_8v8j8g",
  "page": "_page_8v8j8g",
  "is-primary": "_is-primary_8v8j8g",
  "is-default": "_is-default_8v8j8g"
};
