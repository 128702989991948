export default {
  "file-overview-file-details": "_file-overview-file-details_8dr341",
  "file-overview-versions-tags": "_file-overview-versions-tags_8dr341",
  "file-version-container": "_file-version-container_8dr341",
  "file-version-title": "_file-version-title_8dr341",
  "file-tags-container": "_file-tags-container_8dr341",
  "platform-android": "_platform-android_8dr341",
  "platform-apple": "_platform-apple_8dr341",
  "store-logo-container": "_store-logo-container_8dr341",
  "appstore-logo-vector": "_appstore-logo-vector_8dr341"
};
