import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @archive.isAvailable}}\n  {{#if this.downloadArchive.isRunning}}\n    <AkLoader @size={{16}} />\n  {{else}}\n    <AkIconButton\n      data-test-archiveAction-btn\n      @variant='outlined'\n      @size='small'\n      {{on 'click' (perform this.downloadArchive)}}\n    >\n      <span class='ak-icon ak-icon-download'></span>\n    </AkIconButton>\n  {{/if}}\n{{/if}}", {"contents":"{{#if @archive.isAvailable}}\n  {{#if this.downloadArchive.isRunning}}\n    <AkLoader @size={{16}} />\n  {{else}}\n    <AkIconButton\n      data-test-archiveAction-btn\n      @variant='outlined'\n      @size='small'\n      {{on 'click' (perform this.downloadArchive)}}\n    >\n      <span class='ak-icon ak-icon-download'></span>\n    </AkIconButton>\n  {{/if}}\n{{/if}}","moduleName":"irene/components/organization-archive/list/archive-action/index.hbs","parseOptions":{"srcName":"irene/components/organization-archive/list/archive-action/index.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class OrganizationArchiveListArchiveActionComponent extends Component {
  @service('notifications') notify;
  @service intl;

  tdownloadFailed = this.intl.t('organizationArchiveDownloadErrored');

  downloadArchive = task(async () => {
    const downloadURL = await this.args.archive.downloadURL();

    if (downloadURL) {
      window.open(downloadURL);
      return;
    }

    this.notify.error(this.tdownloadFailed);
  });
}
