import ENUMS from 'irene/enums';
import config from 'irene/config/environment';
import installPendo from 'irene/utils/install-pendo';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import installHotjar from 'irene/utils/install-hotjar';
import customerSuccessBox from 'irene/utils/customer-success-box';
import { registerDateLibrary } from 'ember-power-calendar';
import DateUtils from 'irene/utils/power-calendar-dayjs';
import { createServer } from 'miragejs/server';

config.isDevknox = 'secure.devknox.io' === location.hostname;
config.isAppknox = !config.isDevknox;

if (config.isAppknox) {
  config.platform = ' | Appknox';
  config.product = ENUMS.PRODUCT.APPKNOX;
} else {
  config.platform = ' | Devknox';
  config.product = ENUMS.PRODUCT.DEVKNOX;
}

installPendo();
installHotjar();
customerSuccessBox();
registerDateLibrary(DateUtils);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

// if (window.Cypress) {
//   // If your app makes requests to domains other than / (the current domain), add them
//   // here so that they are also proxied from your app to the handleFromCypress function.
//   // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
//   let otherDomains = [];
//   let methods = ['get', 'put', 'patch', 'post', 'delete'];

//   createServer({
//     environment: 'test',
//     routes() {
//       for (const domain of ['/', ...otherDomains]) {
//         for (const method of methods) {
//           this[method](`${domain}*`, async (schema, request) => {
//             let [status, headers, body] = await window?.handleFromCypress(
//               request
//             );

//             return new Response(status, headers, body);
//           });
//         }
//       }

//       // If your central server has any calls to passthrough(), you'll need to duplicate them here
//       // this.passthrough('https://analytics.google.com')
//     },
//   });
// }
