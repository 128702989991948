import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck : or}}\n<AkPopover\n  data-test-ak-menu-popover\n  local-class='ak-menu-root'\n  @anchorRef={{@anchorRef}}\n  @placement='bottom-start'\n  @modifiers={{this.modifiers}}\n  @arrow={{@arrow}}\n  @sameWidthAsRef={{@sameWidthAsRef}}\n  @hasBackdrop={{true}}\n  @onBackdropClick={{this.handleBackdropClick}}\n  @renderInPlace={{@renderInPlace}}\n  @containerClass={{if @arrow this.classes.akMenuArrowPopoverRoot}}\n>\n  <div\n    data-test-ak-menu\n    local-class='ak-menu-dropdown-root\n            {{if @sameWidthAsRef \"ak-menu-dropdown-full-width\"}}'\n  >\n    <AkList local-class='ak-menu-dropdown-list' role={{or @role 'menu'}}>\n      {{yield\n        (hash listItem=(component 'ak-list/item' button=true role='menuitem'))\n      }}\n    </AkList>\n  </div>\n</AkPopover>", {"contents":"{{! @glint-nocheck : or}}\n<AkPopover\n  data-test-ak-menu-popover\n  local-class='ak-menu-root'\n  @anchorRef={{@anchorRef}}\n  @placement='bottom-start'\n  @modifiers={{this.modifiers}}\n  @arrow={{@arrow}}\n  @sameWidthAsRef={{@sameWidthAsRef}}\n  @hasBackdrop={{true}}\n  @onBackdropClick={{this.handleBackdropClick}}\n  @renderInPlace={{@renderInPlace}}\n  @containerClass={{if @arrow this.classes.akMenuArrowPopoverRoot}}\n>\n  <div\n    data-test-ak-menu\n    local-class='ak-menu-dropdown-root\n            {{if @sameWidthAsRef \"ak-menu-dropdown-full-width\"}}'\n  >\n    <AkList local-class='ak-menu-dropdown-list' role={{or @role 'menu'}}>\n      {{yield\n        (hash listItem=(component 'ak-list/item' button=true role='menuitem'))\n      }}\n    </AkList>\n  </div>\n</AkPopover>","moduleName":"irene/components/ak-menu/index.hbs","parseOptions":{"srcName":"irene/components/ak-menu/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Modifier } from '@popperjs/core';
import { WithBoundArgs } from '@glint/template';

import AkListItemComponent from 'irene/components/ak-list/item';
import styles from './index.scss';

export interface AkMenuSignature {
  Args: {
    anchorRef?: HTMLElement | null;
    offset?: [number, number];
    renderInPlace?: boolean;
    arrow?: boolean;
    onClose?: () => void;
    sameWidthAsRef?: boolean;
    role?: string;
  };
  Blocks: {
    default: [
      { listItem: WithBoundArgs<typeof AkListItemComponent, 'button' | 'role'> }
    ];
  };
}

export default class AkMenuComponent extends Component<AkMenuSignature> {
  get modifiers(): Partial<Modifier<string, object>>[] {
    return [
      {
        name: 'offset',
        options: {
          offset: this.args.offset || [0, this.args.arrow ? 0 : 3],
        },
      },
    ];
  }

  get classes() {
    return {
      akMenuArrowPopoverRoot: styles['ak-menu-arrow-popover-root'],
    };
  }

  @action
  handleBackdropClick() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkMenu: typeof AkMenuComponent;
  }
}
