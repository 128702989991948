export default {
  "risk-tag": "_risk-tag_224qj4",
  "risk-tag-label": "_risk-tag-label_224qj4",
  "risk-tag-icon": "_risk-tag-icon_224qj4",
  "is-critical": "_is-critical_224qj4",
  "is-danger": "_is-danger_224qj4",
  "is-warning": "_is-warning_224qj4",
  "is-info": "_is-info_224qj4",
  "is-success": "_is-success_224qj4",
  "is-default": "_is-default_224qj4",
  "is-waiting": "_is-waiting_224qj4",
  "is-progress": "_is-progress_224qj4",
  "is-errored": "_is-errored_224qj4"
};
