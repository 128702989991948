import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr>\n  <td>\n    {{@project.id}}\n  </td>\n\n  <td>\n    {{@project.packageName}}\n  </td>\n\n  <td class='table-button'>\n    <LinkTo\n      @route='authenticated.security.files'\n      @model={{@project.id}}\n      class='button mp-all-scans is-primary'\n    >\n      View Files\n    </LinkTo>\n  </td>\n</tr>", {"contents":"<tr>\n  <td>\n    {{@project.id}}\n  </td>\n\n  <td>\n    {{@project.packageName}}\n  </td>\n\n  <td class='table-button'>\n    <LinkTo\n      @route='authenticated.security.files'\n      @model={{@project.id}}\n      class='button mp-all-scans is-primary'\n    >\n      View Files\n    </LinkTo>\n  </td>\n</tr>","moduleName":"irene/components/security/project-search-overview/index.hbs","parseOptions":{"srcName":"irene/components/security/project-search-overview/index.hbs"}});
import Component from '@glimmer/component';
import SecurityProjectModel from 'irene/models/security/project';

export interface SecurityProjectSearchOverviewSignature {
  Element: HTMLElement;
  Args: {
    project: SecurityProjectModel;
  };
}

export default class SecurityProjectSearchOverviewComponent extends Component<SecurityProjectSearchOverviewSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::ProjectSearchOverview': typeof SecurityProjectSearchOverviewComponent;
  }
}
