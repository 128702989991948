import { Factory } from 'miragejs';

import { faker } from '@faker-js/faker';

export default Factory.extend({
  id(i) {
    return 100 + i + 1;
  },

  sb_vulnerability() {
    const id = this.id as number;
    const vulnerability_id = faker.lorem.slug();

    return {
      advisories: [
        {
          title: '',
          url: 'https://nvd.nist.gov/vuln/detail/CVE-2023-24459',
        },
        {
          title: 'GitHub Advisories',
          url: 'https://github.com/advisories/GHSA-67w4-w877-jv29',
        },
      ],

      bom_ref: vulnerability_id,
      cwes: [862],
      description: 'Missing permission check in Jenkins BearyChat Plugin',
      detail:
        'A missing permission check in Jenkins BearyChat Plugin 3.0.2 and earlier allows attackers with Overall/Read permission to connect to an attacker-specified URL.',

      id,

      ratings: [
        {
          method: 'CvssV3',
          source: {
            name: 'GitHub Advisories',
            url: 'https://github.com/advisories/GHSA-67w4-w877-jv29',
          },
          vector: 'CVSS:3.1/AV:N/AC:L/PR:L/UI:N/S:U/C:N/I:H/A:N',
        },
      ],

      recommendation: '',

      references: [
        {
          id: 'https://www.jenkins.io/security/advisory/2023-01-24/#SECURITY-2745',
          source: {
            name: 'WEB',
            url: 'https://www.jenkins.io/security/advisory/2023-01-24/#SECURITY-2745',
          },
        },
      ],

      source_name: 'GitHub Advisories',

      source_url: 'https://github.com/advisories/GHSA-67w4-w877-jv29',

      severity: faker.datatype.number({ min: 1, max: 7 }),

      score: faker.datatype.number(10),

      vulnerability_id,
    };
  },

  versions: () => [
    {
      version_range:
        '{"version":"1.0.0","less_than":"2.0.0","less_than_or_equal":null}',
      version: null,
    },
    {
      version_range:
        '{"version":"0","less_than":null,"less_than_or_equal":"3.0.2"}',
      version: null,
    },
  ],
});
