export default {
  "operational-status": "_operational-status_od7kog",
  "operational-text": "_operational-text_od7kog",
  "online": "_online_od7kog",
  "unavailable-text": "_unavailable-text_od7kog",
  "status-label": "_status-label_od7kog",
  "system-status-container": "_system-status-container_od7kog",
  "system-status-box": "_system-status-box_od7kog",
  "loading-indicator": "_loading-indicator_od7kog",
  "system-status-table": "_system-status-table_od7kog"
};
