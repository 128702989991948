export default {
  "ak-form-control": "_ak-form-control_1bcyr",
  "ak-form-label": "_ak-form-label_1bcyr",
  "ak-form-label-spacing": "_ak-form-label-spacing_1bcyr",
  "ak-form-helper-text": "_ak-form-helper-text_1bcyr",
  "ak-error-form-helper-text": "_ak-error-form-helper-text_1bcyr",
  "ak-select-dropdown": "_ak-select-dropdown_1bcyr",
  "ak-select-trigger-root": "_ak-select-trigger-root_1bcyr",
  "ak-select-trigger": "_ak-select-trigger_1bcyr",
  "ak-select-trigger-error": "_ak-select-trigger-error_1bcyr"
};
