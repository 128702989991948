import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div class='tabs is-medium is-boxed is-centered margin-top50 mb-3'>\n    <ul>\n      {{#each this.menuItems as |item|}}\n        <li class={{if item.isActive 'is-active'}}>\n          <LinkTo @route={{item.route}}>\n            {{item.label}}\n          </LinkTo>\n        </li>\n      {{/each}}\n    </ul>\n  </div>\n</div>\n\n{{yield}}", {"contents":"<div>\n  <div class='tabs is-medium is-boxed is-centered margin-top50 mb-3'>\n    <ul>\n      {{#each this.menuItems as |item|}}\n        <li class={{if item.isActive 'is-active'}}>\n          <LinkTo @route={{item.route}}>\n            {{item.label}}\n          </LinkTo>\n        </li>\n      {{/each}}\n    </ul>\n  </div>\n</div>\n\n{{yield}}","moduleName":"irene/components/security/nav-menu/index.hbs","parseOptions":{"srcName":"irene/components/security/nav-menu/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';

export interface SecurityNavMenuSignature {
  Blocks: { default: [] };
}

export default class SecurityNavMenuComponent extends Component<SecurityNavMenuSignature> {
  @service declare router: RouterService;

  get currentRoute() {
    return this.router.currentRouteName;
  }

  get menuItems() {
    return [
      {
        route: 'authenticated.security.projects',
        label: 'Projects',
        isActive: [
          'authenticated.security.projects',
          'authenticated.security.files',
          'authenticated.security.file',
        ].includes(this.currentRoute),
      },
      {
        route: 'authenticated.security.downloadapp',
        label: 'Download App',
        isActive: this.currentRoute === 'authenticated.security.downloadapp',
      },
      {
        route: 'authenticated.security.purgeanalysis',
        label: 'Purge API Analyses',
        isActive: this.currentRoute === 'authenticated.security.purgeanalysis',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::NavMenu': typeof SecurityNavMenuComponent;
  }
}
