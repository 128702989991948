import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkIconButton\n  data-test-teamUserList-actionBtn\n  title='{{t \"removeFromTeam\"}}'\n  {{on 'click' this.openRemoveMemberPrompt}}\n>\n  <AkIcon @iconName='person-remove' />\n</AkIconButton>\n\n<ConfirmBox\n  @isActive={{this.showRemoveMemberPrompt}}\n  @confirmText={{this.confirmText}}\n  @delegate={{this}}\n  @confirmAction={{perform this.removeMember}}\n  @disabled={{this.isRemovingMember}}\n  @justifyActionContent='flex-end'\n  @blurOverlay={{true}}\n>\n  <:content>\n    <AkTextField\n      data-test-teamUserList-promptInput\n      @labelTypographyColor='textPrimary'\n      @label={{t 'promptBox.removeMemberPrompt.description'}}\n      @value={{this.promptMemberName}}\n      @autofocus={{true}}\n    />\n  </:content>\n</ConfirmBox>", {"contents":"<AkIconButton\n  data-test-teamUserList-actionBtn\n  title='{{t \"removeFromTeam\"}}'\n  {{on 'click' this.openRemoveMemberPrompt}}\n>\n  <AkIcon @iconName='person-remove' />\n</AkIconButton>\n\n<ConfirmBox\n  @isActive={{this.showRemoveMemberPrompt}}\n  @confirmText={{this.confirmText}}\n  @delegate={{this}}\n  @confirmAction={{perform this.removeMember}}\n  @disabled={{this.isRemovingMember}}\n  @justifyActionContent='flex-end'\n  @blurOverlay={{true}}\n>\n  <:content>\n    <AkTextField\n      data-test-teamUserList-promptInput\n      @labelTypographyColor='textPrimary'\n      @label={{t 'promptBox.removeMemberPrompt.description'}}\n      @value={{this.promptMemberName}}\n      @autofocus={{true}}\n    />\n  </:content>\n</ConfirmBox>","moduleName":"irene/components/organization-team/member-list/user-action/index.hbs","parseOptions":{"srcName":"irene/components/organization-team/member-list/user-action/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { capitalize } from '@ember/string';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import MeService from 'irene/services/me';
import RealtimeService from 'irene/services/realtime';
import OrganizationTeamModel from 'irene/models/organization-team';
import OrganizationTeamMemberModel from 'irene/models/organization-team-member';

export interface OrganizationTeamMemberListUserActionComponentSignature {
  Args: {
    team: OrganizationTeamModel;
    member: OrganizationTeamMemberModel;
    reloadTeamMembers: () => void;
  };
  Element: HTMLElement;
}

export default class OrganizationTeamMemberListUserAction extends Component<OrganizationTeamMemberListUserActionComponentSignature> {
  @service declare intl: IntlService;
  @service declare me: MeService;
  @service declare realtime: RealtimeService;
  @service('notifications') declare notify: NotificationService;

  @tracked isRemovingMember = false;
  @tracked showRemoveMemberPrompt = false;
  @tracked promptMemberName = '';

  get confirmText() {
    return capitalize(this.intl.t('remove'));
  }

  /* Open remove-member prompt */
  @action
  openRemoveMemberPrompt() {
    this.showRemoveMemberPrompt = true;
  }

  /* Remove member */
  removeMember = task(async () => {
    try {
      this.isRemovingMember = true;

      const memberName = (await this.args.member.user).username.toLowerCase();
      const promptMemberName = this.promptMemberName.toLowerCase();

      if (promptMemberName !== memberName) {
        throw new Error(this.intl.t('enterRightUserName'));
      }

      const { team } = this.args;

      const user = await this.args.member.user;

      await team.deleteMember(user);

      // reload member list
      this.args.reloadTeamMembers();

      this.notify.success(this.intl.t('teamMemberRemoved'));

      this.showRemoveMemberPrompt = false;
      this.isRemovingMember = false;

      // reload team to update member count
      // for some reason because of 'reloadTeamMembers' this has to be last for test & implementation to work
      await team.reload();
    } catch (e) {
      const err = e as AdapterError;
      let errMsg = this.intl.t('pleaseTryAgain');

      if (err.errors && err.errors.length) {
        errMsg = err.errors[0]?.detail || errMsg;
      } else if (err.message) {
        errMsg = err.message;
      }

      this.notify.error(errMsg);
      this.isRemovingMember = false;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationTeam::MemberList::UserAction': typeof OrganizationTeamMemberListUserAction;
  }
}
