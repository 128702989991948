export default {
  "ak-button-root": "_ak-button-root_1vgcus",
  "ak-button-text-root": "_ak-button-text-root_1vgcus",
  "ak-button-disabled": "_ak-button-disabled_1vgcus",
  "ak-button-loading": "_ak-button-loading_1vgcus",
  "ak-button-text": "_ak-button-text_1vgcus",
  "ak-button-text-underline-none": "_ak-button-text-underline-none_1vgcus",
  "ak-button-filled-primary": "_ak-button-filled-primary_1vgcus",
  "ak-button-outlined-neutral": "_ak-button-outlined-neutral_1vgcus",
  "ak-button-outlined-primary": "_ak-button-outlined-primary_1vgcus",
  "ak-button-loader": "_ak-button-loader_1vgcus",
  "ak-button-left-icon": "_ak-button-left-icon_1vgcus",
  "ak-button-right-icon": "_ak-button-right-icon_1vgcus",
  "ak-button-icon-medium": "_ak-button-icon-medium_1vgcus",
  "ak-button-icon-small": "_ak-button-icon-small_1vgcus"
};
